import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions';
import Layout from '../../../../templates/layout';
import { Button, Alert } from '@mui/material';
import BasePageContent from '../../../../components/utils/BasePageContent';
import { navigate } from 'gatsby-link';

const mapStateToProps = state => ({
  verifyUserEmail: state.verifyUserEmail,
});

class Verifier extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, message: '', success: false, loading: true };
  }

  componentDidMount() {
    const code = this.props.code;
    this.props.actions.verifyUserEmail(code);
  }

  componentDidUpdate(prevProps) {
    const { verifyUserEmail } = this.props;
    if (verifyUserEmail !== prevProps.verifyUserEmail) {
      const { status, reason, message, data } = verifyUserEmail;
      if (data) this.setState({ success: true, loading: false });
      if (status === 'ERROR') {
        this.setState({
          loading: false,
          error: true,
          message: reason || message,
        });
      }
    }
  }

  renderMessage() {
    const { error } = this.state;
    const message = error
      ? 'Link weryfikacyjny jest niepoprawny lub utracił ważność.'
      : 'Twój adres email został potwierdzony';

    const severity = error ? 'error' : 'success';

    return <Alert severity={severity}>{message}</Alert>;
  }

  renderAction() {
    const { error } = this.state;
    const text = error ? 'Wyślij ponownie' : 'Przejdź do logowania';
    const color = error ? '' : 'primary';
    const handleClick = () =>
      navigate(error ? '/email/resend' : '/user/sign-in');

    return (
      <Button
        onClick={handleClick}
        variant='outlined'
        color={color}
        sx={{mt:2}}
      >
        {text}
      </Button>
    );
  }

  render() {
    return (
      <Layout>
        <BasePageContent loading={this.state.loading}>
          {this.renderMessage()}
          {this.renderAction()}
        </BasePageContent>
      </Layout>
    );
  }
}

export const Head = () => <title>Weryfikacja adresu email | Zapłatomat</title>;

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Verifier);
